import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import SessionService from '../../services/session.service';
import { SessionCreateModel } from '../../models/session/session-create';
import { SessionResponse } from '../../models/session/session-response';
import { Role } from '../../models/role/role';
import RoleService from '../../services/role.service';

export const createSession = createAsyncThunk(
  'sessions/Create', 
  async (model: {payload: SessionCreateModel, tenantName: string} , thunkApi) => {
    try {
      const response = await SessionService.create(model.payload);
      if (response.status != 200) {
        throw {
          reason: response.statusText,
          correlationId: response.headers['correlationId'],
        };
      }

      SessionService.session = {
        session: response.data, 
        tenantId: model.payload.tenantId,
        tenant: model.tenantName
      };

      thunkApi.dispatch(fetchRoles());

      return SessionService.session;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const setSelectedCitizen = createAsyncThunk(
  'sessions/selectCitizen', 
  async (citizenId: string | undefined, thunkApi) => {
    try {
      const response = citizenId === '' || !citizenId
        ? await SessionService.clearCitizenSession()
        : await SessionService.updateCitizenSession(citizenId);
      SessionService.session = {
        session: response.data,
        tenantId: SessionService?.session?.tenantId ?? '',
        tenant: SessionService?.session?.tenant ?? '',
      };

      thunkApi.dispatch(fetchRoles());

      return SessionService.session;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const fetchRoles = createAsyncThunk(
  'sessions/fetchRoles',
  async (_, thunkApi) => {
    try {
      const session = SessionService.session;
      if (!session) {
        throw new Error('No session found');
      }

      const rolePromises = session.session.roles.map((roleId: string) =>
        RoleService.get(roleId, session.tenantId)
      );

      const roles = await Promise.all(rolePromises);
      const rolesData = roles.map((response: any) => response.data);

      return rolesData;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

export interface SessionsState {
  status: 'idle'| 'loading' | 'failed' | 'success';
  error: string | null;
  session: SessionResponse | null;
  tenantId: string | null;
  tenantName: string | null;
  isAdmin: boolean;
  fullRoles: Role[]; 
}

const initialState = {
  status: 'idle',
  error: null,
  session: SessionService.session?.session || null,
  tenantId: null,
  tenantName: null,
  isAdmin: false, 
  fullRoles: [], 
} as SessionsState

const sessionsSlice = createSlice({
  name: 'Sessions',
  initialState,
  reducers: {
    clear: (state) => {
      SessionService.clear();
      state.session = null;
      state.tenantId = null;
      state.tenantName = null;
      state.status = 'idle';
      state.isAdmin = false; 
      state.fullRoles = []; 
    },
    updateRole: (state, action: PayloadAction<{ roleId: string, roleData: Partial<Role> }>) => {
      const { roleId, roleData } = action.payload;
      const index = state.fullRoles.findIndex(role => role.roleId === roleId);
      if (index !== -1) {
        state.fullRoles[index] = { ...state.fullRoles[index], ...roleData };
      }
    }
  },
    
  extraReducers(builder) {
    builder
      .addCase(createSession.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createSession.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'success';
        state.session = action.payload.session;
        state.tenantId = action.payload.tenantId;
        state.tenantName = action.payload.tenant;
      })
      .addCase(createSession.rejected, (state, action: PayloadAction<any>) => {
        state.status = 'failed';
        state.error = action.payload;
        state.session = null;
        state.isAdmin = false; // Reset isAdmin
        state.fullRoles = []; // Reset fullRoles
      })
      .addCase(setSelectedCitizen.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setSelectedCitizen.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'success';
        state.session = action.payload.session;
        state.tenantId = action.payload.tenantId;
        state.tenantName = action.payload.tenant;
      })
      .addCase(setSelectedCitizen.rejected, (state, action: PayloadAction<any>) => {
        state.status = 'failed';
        state.error = action.payload;
        state.session = null;
        state.isAdmin = false; // Reset isAdmin
        state.fullRoles = []; // Reset fullRoles
      })
      .addCase(fetchRoles.fulfilled, (state, action: PayloadAction<Role[]>) => {
        state.fullRoles = action.payload;
        state.isAdmin = action.payload.some((role: Role) => role.isAdmin); // Compute isAdmin based on fullRoles
      });
  },
});

export const { updateRole } = sessionsSlice.actions;
const { reducer } = sessionsSlice;
export default reducer;