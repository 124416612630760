import { FC, lazy } from 'react';
import { PaginationContext } from '../../context/pagination-context';
import { Role } from '../../models/role/role';
import { useNavigate } from 'react-router-dom';
import './roles-search-results-table.scss';


const Pagination = lazy(() => import('../pagination/pagination'));

export type Props = {
  tenantName: string;
  tableId: string;
  page: number;
  totalPages: number;
  setPage: any;
  tableHeaders: Array<string>;
  tableCells: Role[] | null;
};

const RoleSearchResultsTable: FC<Props> = ({
  tenantName,
  tableId,
  page,
  setPage,
  totalPages,
  tableHeaders,
  tableCells,
}: Props) => {
  const navigate = useNavigate();

  const handleEditRole = (role: Role) => {
    navigate(`/${tenantName}/role-management/${role.roleId}`, { state: { role, isEditing : true } });
  };

  return (
    <div>
      <table id={tableId} className="govuk-table responsive-table">
        <caption className="govuk-table__caption" />
        <thead className="govuk-table__head">
          <tr className="govuk-table__row">
            {tableHeaders.map((row, idx) => (
              <th key={idx} scope="col" className="govuk-table__header">
                {row}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="govuk-table__body">
          {tableCells?.map((role) => (
            <tr className="govuk-table__row" key={role.roleId}>
              {role ? (
                <>
                  <td data-th="Role Name" className="govuk-table__cell govuk-!-width-two-thirds">
                    {role.name}
                  </td>
                  <td data-th="Actions" className="govuk-table__cell govuk-!-width-one-third govuk-!-text-align-right">
                    <button
                      className="govuk-button search-button"
                      onClick={() => handleEditRole(role)}
                    >
                      Edit
                    </button>
                  </td>
                </>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationContext.Provider value={{ page, setPage }}>
        <div>
          Page {page} of {totalPages}
          <br/><br/>
          <Pagination pageNumber={page} totalPages={totalPages} />
        </div>
      </PaginationContext.Provider>
    </div>
  );
};

export default RoleSearchResultsTable;