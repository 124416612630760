import React, { FormEvent, Suspense, useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/loader/loader';
import DashboardLayout from '../../../components/layout/dashboard/dashboard-layout';
import ProtectedRoute from '../../../routes/protected-route';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import UserDataService from '../../../services/user.service';
import { UserAccountModel } from '../../../models/users/user-account.model';
import { useMsal } from '@azure/msal-react';
import { ToastContainer, toast } from 'react-toastify';
import sessionService from '../../../services/session.service';
import 'react-toastify/dist/ReactToastify.min.css';
import './user-profile.scss';
import { EndSessionRequest } from '@azure/msal-browser';
import SelectRole from '../../../components/select/select-role';
import { Role } from '../../../models/role/role';


function UserProfile() {
  const navigate = useNavigate();
  const msal = useMsal();
  const tenant = useSelector((state: any) => state.tenants.entities);
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<UserAccountModel>();
  const [form, setForm] = useState({ firstName: '', lastName: '', roles: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [disabled, setDisabled] = useState(true);
  const isAdmin = useSelector((state: any) => state.sessions.isAdmin);
  const [selectedRole, setSelectedRole] = useState<Role | null>();


  useEffect(() => {
    setLoading(true);
    UserDataService.get(userId as string, false)
      .then((response) => {
        setUser(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [userId]);

  const notify = () => {
    toast.warn('You will revoke your privileges and be logged out the current session', {
      position: toast.POSITION.TOP_CENTER,
    });
  };


  useEffect(() => {
    if (selectedRole) {
      handleInputChange({ target: { value: selectedRole.roleId } } as React.ChangeEvent<HTMLSelectElement>, 'roles');
    }
  }, [selectedRole]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    key: string) => {
    const newFormState = { ...form, [key]: event.target.value };
    setForm(newFormState);
    setDisabled(!(newFormState.firstName || newFormState.lastName || newFormState.roles));

    if (key === 'roles' && user?.externalId === msal.accounts[0].localAccountId 
      && isAdmin 
      && !selectedRole?.isAdmin) {
      notify();
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (user) {
      const updatedUser = { ...user };

      if (form.firstName) updatedUser.firstName = form.firstName;
      if (form.lastName) updatedUser.lastName = form.lastName;
      if (form.roles) updatedUser.roles = [form.roles];

      UserDataService.update(user.id, updatedUser)
        .then((__) => {
          if (
            isAdmin &&
            !selectedRole?.isAdmin&&
            user?.externalId === msal.accounts[0].localAccountId
          ) {
            try {
              sessionService.signOut().then(_ => {
                const endSessionRequest: EndSessionRequest = {
                  account: msal.instance.getAccountByHomeId(msal.accounts[0]?.homeAccountId),
                  postLogoutRedirectUri: `/${tenant.name}/welcome`
                };
                msal.instance.logoutRedirect(endSessionRequest);
              }).then(() => {
                sessionStorage.removeItem('accp:session');
              });
            } catch (error) {
              console.error(error);
            };
          } else {
            navigate(`/${tenant.name}/user-management`);
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <DashboardLayout>
        <ProtectedRoute>
          {loading ? <LoadingSpinner /> : (
            <div id="userProfile" className="govuk-width-container">
              <main className="govuk-main-wrapper" id="main-content" role="main">
                <div className="govuk-grid-row">
                  <h1>{!error && user ? 'User Profile' : 'Unable to get user'}</h1>
                </div>
                {!error && user && (
                  <>
                    <div className="govuk-grid-row">
                      <div className="govuk-grid-column-full profileCard__wrapper">
                        <div className="profileCard__row">
                          <h2 className="govuk-heading-s govuk-!-font-weight-bold">
                              Name
                          </h2>
                          <div className="govuk-body govuk-!-margin-bottom-4 justified">
                            {user && user.firstName + ' ' + user.lastName}
                          </div>
                        </div>
                        <div className="profileCard__row">
                          <h2 className="govuk-heading-s govuk-!-font-weight-bold">
                              Contact Details
                          </h2>
                          <div className="govuk-body govuk-!-margin-bottom-4 justified">
                            {user && user.email}
                          </div>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="govuk-grid-row">
                        <div className="govuk-grid-column-full no-min-height profileCard__wrapper">
                          <div className="profileCard__row">
                            <h2 className="govuk-heading-s govuk-!-font-weight-bold">
                                Forename
                            </h2>
                            <div className="govuk-body govuk-!-margin-bottom-4 justified">
                              <input className="govuk-input"
                                name="firstName"
                                type="text"
                                aria-label={'Forename'}
                                placeholder='Forename' 
                                maxLength={30}
                                onChange={(e) => handleInputChange(e, 'firstName')}/>
                            </div>
                          </div>
                          <div className="profileCard__row">
                            <h2 className="govuk-heading-s govuk-!-font-weight-bold">
                                Surname
                            </h2>
                            <div className="govuk-body govuk-!-margin-bottom-4 justified">
                              <input className="govuk-input"
                                name="lastName"
                                type="text"
                                aria-label={'Surname'}
                                placeholder='Surname' 
                                maxLength={30}
                                onChange={(e) => handleInputChange(e, 'lastName')}/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="govuk-grid-row">
                        <div className="govuk-grid-column-full no-min-height profileCard__wrapper">
                          <div className="profileCard__row">
                            <h2 className="govuk-heading-s govuk-!-font-weight-bold">
                              Permission
                            </h2>
                            <SelectRole title='Select the permission for the user' onRoleChange={setSelectedRole} defaultValue={user.roles[0]} disableDefault/>
                          </div>
                        </div>
                      </div>
                      <div className="actions__wrapper">
                        <div className="profile__actions">
                          <Link to={`/${tenant.name}/user-management`} className="govuk-button">
                            Back
                          </Link>
                          <button type="submit" className="govuk-button" disabled={disabled}>
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </main>
            </div>
          )}
        </ProtectedRoute>
      </DashboardLayout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Suspense>
  )
}

export default UserProfile;