import { ChangeRequestDictionary } from '../models/formEntities/change-request-dictionary'

export const CHANGE_REQUEST_IDS: ChangeRequestDictionary[] = [
    {
        '31acba1c-5ed4-11ee-8c99-0242ac120002':
        {
            title: 'Requesting Proxy Access',
            description: 'I would like to request proxy access to allow me to submit information on behalf of someone else',
            paragraph: '<p>Please fill in the form it has been broken down into small sections ' +
            'that you can jump to and complete separately. Keep track of your completion on this page</p>' +
            '<div class="govuk-inset-text">This form will take approximately 15 minutes to complete but ' +
            'you can save each section separately and come back to it later.</div>'
        }
    },
    {
        'afb2ebb2-5ed8-11ee-8c99-0242ac120002':
        {
            title: 'Giving Proxy Access',
            description: 'I would like to give proxy access to allow someone to submit information on my behalf',
            paragraph: '<p>Please fill in the form it has been broken down into small sections ' +
            'that you can jump to and complete separately. Keep track of your completion on this page</p>' +
            '<div class="govuk-inset-text">This form will take approximately 15 minutes to complete but ' +
            'you can save each section separately and come back to it later.</div>'
        }
    },
    {
        'b73620bc-5edc-11ee-8c99-0242ac120002':
        {
            title: 'Removing Proxy Access',
            description: 'I would like to remove proxy access for someone who can presently act upon my behalf',
            paragraph: '<p>Please fill in the form it has been broken down into small sections ' +
            'that you can jump to and complete separately. Keep track of your completion on this page</p>' +
            '<div class="govuk-inset-text">This form will take approximately 15 minutes to complete but ' +
            'you can save each section separately and come back to it later.</div>'
        }
    },
    {
        '3effaf4e-5ede-11ee-8c99-0242ac120002':
        {
            title: 'Change to Personal Details',
            description: 'I would like to request a change in personal details to be made to my (or my proxies) personal details recorded with the local authority',
            paragraph: '<p>Please fill in the form. Keep track of your completion on this page</p>' +
            '<div class="govuk-inset-text">This form will take approximately 10 minutes to complete but ' +
            'you can save it and come back to it later.</div>'
        }
    },
    {
        '36fe0194-5ee1-11ee-8c99-0242ac120002':
        {
            title: 'Submit additional Information/Evidence',
            description: 'I would like to submit additional information to support previously submitted requests',
            paragraph: '<p>Please fill in the form. Keep track of your completion on this page</p>' +
            '<div class="govuk-inset-text">This form will take approximately 10 minutes to complete but ' +
            'you can save it and come back to it later.</div>'
        }
    }
];