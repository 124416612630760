import React, { useEffect, useState } from 'react';
import RoleService from '../../services/role.service';
import { Role } from '../../models/role/role';
import { useAppSelector } from '../../hooks/useTypedSelector';

type SelectRoleProps = {
  onRoleChange: (role: Role | null) => void;
  title: string;
  defaultValue?: string;
  disableDefault?: boolean;
};

const SelectRole = ({ onRoleChange, title, defaultValue ='', disableDefault = false }: SelectRoleProps) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const tenantId = useAppSelector((state) => state.tenants.entities.id);
  const [selectedRoleId, setSelectedRoleId] = useState<string | undefined>(defaultValue);

  useEffect(() => {
    if (tenantId) {
      RoleService.getAll(tenantId)
        .then((response) => {
          setRoles(response.data);
          if (defaultValue) {
            const defaultRole = response.data.find(role => role.roleId === defaultValue);
            if (defaultRole) {
              onRoleChange(defaultRole);
            }
          }
        })
        .catch((err) => {
          console.error('Error fetching roles:', err);
        });
    }
  }, [tenantId, defaultValue, onRoleChange]);

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRoleId = event.target.value;
    setSelectedRoleId(selectedRoleId);
    if (selectedRoleId === '') {
      onRoleChange(null);
    } else {
      const selectedRole = roles.find(role => role.roleId === selectedRoleId);
      if (selectedRole) {
        onRoleChange(selectedRole);
      }
    }
  };

  return (
    <div className="govuk-form-group govuk-!-margin-0 govuk-!-padding-0">
      <select
        className="govuk-select govuk-!-padding-0"
        id="role"
        name="role"
        onChange={handleRoleChange}
        value={selectedRoleId || ''}
      >
        <option value='' disabled={disableDefault}>
          {title}
        </option>
        {roles.map((role) => (
          <option key={role.roleId} value={role.roleId}>
            {role.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectRole;