import { Suspense, lazy, useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/useTypedSelector';
import { useNavigate, Link } from 'react-router-dom';
import LoadingSpinner from '../../components/loader/loader';
import ProtectedRoute from '../../routes/protected-route';
import RoleService from '../../services/role.service';
import { Role } from '../../models/role/role';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import './role-management.scss';
import RoleSearchResultsTable from '../../components/search/roles-search-results-table';
const DashboardLayout = lazy(() => import('../../components/layout/dashboard/dashboard-layout'));

type Props = {
  pageNumber: number;
};

const RoleManagement = (props: Props): JSX.Element => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [rolesLoading, setRolesLoading] = useState(true);
  const [page, setPage] = useState<number>(props.pageNumber);
  const [totalPages, setTotalPages] = useState<number>(1);
  const dispatch = useAppDispatch();
  const tenantName = window.location.pathname.split('/')[1];
  const tenantId = useSelector((state: RootState) => state.tenants.entities.id);
  const navigate = useNavigate();
  const isAdmin = useSelector((state: RootState) => state.sessions.isAdmin);

  useEffect(() => {
    setRolesLoading(true);
    RoleService.getAll(tenantId)
      .then((response) => {
        setRoles(response.data);
        setTotalPages(Math.ceil(response.data.length / 10)); 
      })
      .catch((err) => {
        console.error('Error fetching roles:', err);
        if (tenantName) {
          const path = `/${tenantName}/dashboard`;
          navigate(path);
        }
      })
      .finally(() => setRolesLoading(false));
  }, [tenantId, tenantName, page]);

  useEffect(() => {
    if (!isAdmin) {
      const path = `/${tenantName}/welcome`;
      navigate(path);
    }
  }, [isAdmin, tenantName, navigate]);

  const tableHeaders = ['Role Name', ''];

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <DashboardLayout>
        <ProtectedRoute>
          <div id="rolesManagement" className="role-management-container govuk-width-container">
            <main className="govuk-main-wrapper main__container" id="main-content" role="main">
              <div className="govuk-grid-row">
                <h1>Role Management</h1>
                <div className="button-container">
                  <Link
                    to={`/${tenantName}/role-management/new`}
                    className="govuk-button search-button"
                  >
                    New Role
                  </Link>
                </div>
                {rolesLoading ? (
                  <LoadingSpinner />
                ) : (
                  <RoleSearchResultsTable
                    tenantName={tenantName}
                    tableId="rolesTable"
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                    tableHeaders={tableHeaders}
                    tableCells={roles.slice((page - 1) * 10, page * 10)} // Paginate roles
                  />
                )}
              </div>
            </main>
          </div>
        </ProtectedRoute>
      </DashboardLayout>
    </Suspense>
  );
};

export default RoleManagement;
