import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useTypedSelector';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { getAllSchemas } from '../../slices/form/form-management-slice';
import { FormListModel } from '../../models/forms/form-list.model';
import { AppDispatch } from '../../store/store';
import LoadingSpinner from '../../components/loader/loader';
import { CHANGE_REQUEST_IDS } from '../../constants/change-requests-constants';
import ProtectedRoute from '../../routes/protected-route';
import roleService from '../../services/role.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Role } from '../../models/role/role';
import './role-management.scss';
import { updateRole } from '../../slices/sessions/session-slice';

import {
  SelfAssessmentFormId as selfAssessmentId,
  FinancialAssessmentFormId as financialAssessmentId,
  UserRegistrationFormId as userRegistrationId,
} from '../../constants/form-constants';
const DashboardLayout = lazy(() => import('../../components/layout/dashboard/dashboard-layout'));

const fetchAllSchemas = async (
  dispatch: AppDispatch,
  tenantId: string,
): Promise<FormListModel[]> => {
  let allSchemas: FormListModel[] = [];
  let page = 1;
  const pageSize = 20;
  let hasMore = true;

  while (hasMore) {
    const response = await dispatch(getAllSchemas({ tenantId, pageSize, page })).unwrap();
    allSchemas = [...allSchemas, ...response.schemaList];
    hasMore = response.schemaList.length === pageSize;
    page += 1;
  }
  return allSchemas;
};

const separateDefaultFromCustomForms = (formsList: FormListModel[]) => {
  let customForms: FormListModel[] = [];
  const changeRequestIds = CHANGE_REQUEST_IDS.map((obj) => Object.keys(obj)[0]);
  const schemaIdsToExclude = new Set([
    userRegistrationId,
    financialAssessmentId,
    selfAssessmentId,
    ...changeRequestIds,
  ]);

  customForms = formsList.filter((form) => !schemaIdsToExclude.has(form.schemaId));

  return customForms;
};

const RoleEditor = () => {
  const location = useLocation();
  const { role = {} as Role, isEditing = false } = location.state || {};
  const dispatch = useAppDispatch();
  const tenant = useSelector((state: any) => state.tenants.entities);
  const [customForms, setCustomForms] = useState<FormListModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [roleName, setRoleName] = useState<string>(isEditing ? role.name : '');
  const [selectedForms, setSelectedForms] = useState<Set<string>>(isEditing ? new Set(role.forms) : new Set());
  const [isAdmin, setIsAdmin] = useState<boolean>(isEditing ? role.isAdmin : false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSchemas = async () => {
      if (!tenant.id) return;
      try {
        const allSchemas = await fetchAllSchemas(dispatch, tenant.id);
        const customFormsList = separateDefaultFromCustomForms(allSchemas);
        setCustomForms(customFormsList);
      } catch (err) {
        console.error('Error fetching schemas:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchSchemas();
  }, [tenant.id, dispatch]);

  const handleFormCheckboxChange = (formId: string) => {
    setSelectedForms((prevSelectedForms) => {
      const newSelectedForms = new Set(prevSelectedForms);
      if (newSelectedForms.has(formId)) {
        newSelectedForms.delete(formId);
      } else {
        newSelectedForms.add(formId);
      }
      return newSelectedForms;
    });
  };

  const handleCancel = () => {
    navigate(`/${tenant.name}/role-management`);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const roleData = {
      name: roleName,
      forms: Array.from(selectedForms),
      isAdmin,
      tenantId: tenant.id,
    };

    try {
      let response;
      if (isEditing) {
        if (!role.roleId) {
          toast.error('Role ID is undefined. Cannot update role.');
          return;
        }
        response = await roleService.put(role.roleId, tenant.id, roleData);
      } else {
        response = await roleService.post(roleData);
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(`Role ${isEditing ? 'updated' : 'created'} successfully!`);
        if (isEditing && role.roleId) {
          dispatch(updateRole({ roleId: role.roleId, roleData }));
        }
        navigate(`/${tenant.name}/role-management`);
      } else {
        throw new Error(`Failed to ${isEditing ? 'update' : 'create'} role`);
      }
    } catch (error) {
      console.error(`Error ${isEditing ? 'updating' : 'creating'} role:`, error);
      toast.error(`Error ${isEditing ? 'updating' : 'creating'} role. Please try again.`);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <DashboardLayout>
        <ProtectedRoute>
          <div id="rolesManagement" className="role-management-container govuk-width-container">
            <main className="govuk-main-wrapper main__container" id="main-content" role="main">
              <div className="govuk-grid-row">
                <h1 className="govuk-heading-l">{isEditing ? 'Edit Role' : 'Add New Role'}</h1>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="govuk-form-group govuk-!-margin-bottom-6">
                  <label className="govuk-label govuk-label--s" htmlFor="role-name">
                    Role Name
                  </label>
                  <input
                    className="govuk-input"
                    id="role-name"
                    name="role-name"
                    type="text"
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                    required
                  />
                </div>
                <fieldset className="govuk-fieldset govuk-!-margin-bottom-6">
                  <legend className="govuk-fieldset__legend legend__fieldset govuk-!-margin-bottom-3">
                    <b className="govuk-fieldset__legend--s govuk-!-margin-right-1">Assign Forms</b>
                  </legend>
                  {customForms.map((form) => (
                    <div className="govuk-checkboxes__item" key={form.schemaId}>
                      <input
                        className="govuk-checkboxes__input"
                        id={`form-${form.schemaId}`}
                        name="forms"
                        type="checkbox"
                        value={form.schemaId}
                        checked={selectedForms.has(form.schemaId)}
                        onChange={() => handleFormCheckboxChange(form.schemaId)}
                      />
                      <label
                        className="govuk-label govuk-checkboxes__label"
                        htmlFor={`form-${form.schemaId}`}
                      >
                        {form.schemaName}
                      </label>
                    </div>
                  ))}
                </fieldset>
                <div className="govuk-form-group govuk-!-margin-bottom-6">
                  <div className="govuk-checkboxes__item">
                    <input
                      className="govuk-checkboxes__input"
                      id="is-admin"
                      name="is-admin"
                      type="checkbox"
                      checked={isAdmin}
                      onChange={(e) => setIsAdmin(e.target.checked)}
                    />
                    <label className="govuk-label govuk-checkboxes__label" htmlFor="is-admin">
                      This is an Admin role
                    </label>
                  </div>
                </div>
                <div className="govuk-!-text-align-right">
                  <button type="button" className="govuk-button govuk-button--secondary govuk-!-static-margin-1" onClick={handleCancel}>
                    Cancel Changes
                  </button>
                  <button type="submit" className="govuk-button govuk-!-static-margin-1">
                    {isEditing ? 'Update Role' : 'Create Role'}
                  </button>
                </div>
              </form>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </main>
          </div>
        </ProtectedRoute>
      </DashboardLayout>
    </Suspense>
  );
};

export default RoleEditor;