import { Role } from '../models/role/role';
import http from './http-common';

class RoleService {
  getAll(tenantId: string) {
    return http.get<Role[]>(`/roles/${tenantId}`);
  }

  get(id: string, tenantId: string) {
    return http.get<Role>(`/roles/${tenantId}/${id}`);
  }

  post(body: Role) {
    return http.post<Role>('/roles', body);
  }

  put(id: string, tenantId:string,  body: Role) {
    return http.put<Role>(`/roles/${tenantId}/${id}`, body);
  }

  delete(id: string, tenantId: string) {
    return http.delete<Role>(`/roles/${tenantId}/${id}`);
  }
}

export default new RoleService();