import { FC, useRef } from 'react';
import './search-input.scss';

export type Props = {
    placeholder: string;
    ariaLabel: string;
    onEnter: any;
};

const SearchInput: FC<Props> = ({ placeholder, onEnter, ariaLabel }: Props) => {
    const searchTextReference = useRef<HTMLInputElement>(null);

    function handleSearchInput(event: any): void {
        if (event.key === 'Enter') {
            search();
        }
    }

    function search() {
        if (searchTextReference.current) {
            const finalValue = searchTextReference.current.value.trimStart().trimEnd();
            searchTextReference.current.value = finalValue;
            onEnter(finalValue);
        }
    }

    return (
        <div className='search-input-container'>
            <input className="govuk-input"
                type="text"
                aria-label={ariaLabel ? ariaLabel : 'Search Input'} 
                ref={searchTextReference ? searchTextReference : 'Search...'}
                onKeyDown={(e) => handleSearchInput(e)}
                placeholder={placeholder}></input>
            <button
                type='submit'
                className='govuk-button search-button'
                onClick={search}>Search</button>
        </div>
    )
}

export default SearchInput;